/* global __ctm */

exports.onRouteUpdate = ({ location }) => {
  try {
    if (typeof __ctm !== 'undefined') {
      __ctm.main.runNow()
    }
  } catch (e) {
    console.log(e)
  }
}
